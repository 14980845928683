import React, { useContext, useEffect, useState } from 'react';

import { Button, message, notification, Space, Typography } from 'antd';
import { navigate } from 'gatsby';
import { default as PubNub } from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import LocalizedStrings from 'react-localization';
import store from 'store';
import UIfx from 'uifx';
import { StringParam, useQueryParam } from 'use-query-params';

import { BoothSuggestions, ProductModal, SiemensChatModal, SiemensVideoCallModal } from '.';
import notificationSound from '../../static/chat-notification.mp3';
import { GlobalContext } from '../context/GlobalContextProvider';
import { useBoothCommunication } from '../hooks';
import usePopupSocialLinks from '../hooks/usePopupSocialLinks';
import usePopupVideoPlayer from '../hooks/usePopupVideoPlayer';
import useProfile from '../hooks/useProfile';
import { apiRequester, handleError, handleSuccess, SendUserActions, showNotification } from '../utility';
import {
    CHAT_ACTIONS,
    generateNotifierMessage,
    LIVE_STREAM_ACTIONS,
    NOTIFIER_MESSAGE_IDS,
    VIDEO_CALL_ACTIONS,
} from '../utility/NotifierMessages';
import ChatModal from './ChatModal';
import DownloadBasket from './DownloadBasket';
import FileDownloadsModal from './FileDownloadsModal';
import './EventFrame.css';
import LiteBoothModal from './LiteBoothModal';
import LiveStreamingQuestions from './LiveStreamingQuestions';
import MannedBooths from './MannedBooths';
import OnlineStatus from './OnlineStatus';
import PopupSocialLinks from './PopupSocialLinks';
import { PopupVideoPlayer } from './PopupVideoPlayer';
import ProfileEditor from './ProfileEditor';
import ScheduledRooms from './ScheduledRooms';
import SiemensContactForm from './SiemensContactForm';
import TheatreModal from './TheatreModal';
import VideoCallModal from './VideoCallModal';
import VideoCallRequestModal from './VideoCallRequestModal';
import VideoRoomScheduleModal from './VideoRoomScheduleModal';
import Debug from './Debug';

const CHAT_SUB_KEY = process.env.GATSBY_CHAT_SUB_KEY!;
const CHAT_PUB_KEY = process.env.GATSBY_CHAT_PUB_KEY!;
const loggedInUser = store.get('user') as Users.User;
const EVENT_URL = process.env.GATSBY_EVENT_URL!;
const EVENT_ID = process.env.GATSBY_EVENT_ID!;
const CLIENT_ID = process.env.GATSBY_CLIENT_ID!;
const GATSBY_CLIENT_NAME = process.env.GATSBY_CLIENT_NAME!;
const GATSBY_GLOBAL_VIDEO_BOOTH_ID = process.env.GATSBY_GLOBAL_VIDEO_BOOTH_ID!;
const GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM = process.env.GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM!;
const IFRAME_NAME = 'main-event-iframe';

let sound: UIfx;
const strings = new LocalizedStrings({
    en: {
        callAccepted: 'Call Accepted',
        callAcceptMsg: 'Booth Operator has accepted your video call request',
        callRejected: 'Call Rejected',
        callRejectMsg: 'Booth Operator has declined your video call request',
        newMessage: 'You have received a new chat message',
        waitForOperatorToJoin: 'Waiting for operator to start or schedule the video call',
        waitForOperatorToAccept: 'Waiting for operator to accept your video call. Please stay on the booth',
        cancel: 'Cancel',
        requestCancelled: 'Video call request has been cancelled',
        boothSuggestionNotificationTitle: 'You have new booth suggestions',
        boothSuggestionNotificationDescription:
            'We have found some booths based on your interest. Would you like to view the list of booths?',
        yes: 'Yes',
        no: 'No',
    },
    de: {
        callAccepted: 'Anruf angenommen',
        callAcceptMsg: 'Ihre Videoanruf-Anfrage wurde akzeptiert. ',
        callRejected: 'Anruf abgelehnt',
        callRejectMsg: 'Ihre Videoanruf-Anfrage wurde abgelehnt. ',
        newMessage: 'Sie haben eine neue Chatnachricht erhalten',
        waitForOperatorToJoin: 'Warten, bis der Videoanruf gestartet wird. ',
        waitForOperatorToAccept:
            'Warten, bis Ihre Videoanruf-Anfrage angenommen wird. Bitte bleiben Sie auf dem Stand. ',
        cancel: 'Abbrechen',
        requestCancelled: 'Die Anfrage wurde erfolgreich abgebrochen',
        boothSuggestionNotificationTitle: 'Sie haben neue Standvorschläge',
        boothSuggestionNotificationDescription:
            'Aufgrund Ihres Interesses haben wir einige Stände gefunden. Möchten Sie die Liste der Stände einsehen?',
        yes: 'Ja',
        no: 'Nein',
    },
});

if (typeof window !== 'undefined') sound = new UIfx(notificationSound);

const pubnubClientForChat = new PubNub({
    publishKey: CHAT_PUB_KEY,
    subscribeKey: CHAT_SUB_KEY,
    uuid: loggedInUser?._id,
    authKey: loggedInUser?.chatAuthToken,
    restore: true,
    autoNetworkDetection: true,
    listenToBrowserNetworkEvents: true,
    keepAliveSettings: {
        timeout: 10000,
    },
});

const pubnubClientForActions = new PubNub({
    publishKey: CHAT_PUB_KEY,
    subscribeKey: CHAT_SUB_KEY,
    uuid: loggedInUser?._id,
    authKey: loggedInUser?.chatAuthToken,
    restore: true,
    autoNetworkDetection: true,
    listenToBrowserNetworkEvents: true,
    keepAliveSettings: {
        timeout: 10000,
    },
});

export const EventFrame = () => {
    const context = useContext(GlobalContext);
    const handle = useFullScreenHandle();
    const user = store.get('user') as Users.User;
    const [activeVideoCallRequest, setActiveVideoCallRequest] = useState<Modules.VideoCallRequest>();
    const [ongoingVideoCall, setOngoingVideoCall] = useState<Modules.VideoCallRequest>();
    const [activeFileDownloadsModuleId, setActiveFileDownloadModuleId] = useState<string>();
    const [skipFileDownloadList, setSkipFileDownloadList] = useState<boolean>(false);
    const [showChatModal, setShowChatModal] = useState(false);
    const [showMannedBoothsModal, setShowMannedBoothsModal] = useState(false);
    const [showVideoRoomScheduleModal, setShowVideoRoomScheduleModal] = useState<boolean | string>(false);
    const [showLiveStreamingQuestionsModal, setShowLiveStreamQuestionsModal] = useState<boolean>(false);
    const actionsChannel = `actions.${CLIENT_ID}_${EVENT_ID}_${user?._id}`;
    const [channels, setChannels] = useState<string[]>([`users.${user?._id}`]);
    const [operatorsOnline, setOperatorsOnline] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [refreshLiveStreamQuestion, setRefreshLiveStreamQuestions] = useState(Math.random().toString());
    const [lastAlertedScheduledCallIds, setLastAlertedScheduledCallIds] = useState<string[]>([]);
    const [showBasket, setShowBasket] = useState(false);
    const [showScheduledRoomsPanel, setShowScheduledRoomsPanel] = useState(false);
    const [liteBoothModal, setLiteBoothModal] = useState(false);
    const [showSiemensContactFormId, setShowSiemensContactFormId] = useState<string>();
    const [activeProduct, setActiveProduct] = useState<{ moduleId?: string; productId?: string }>({
        moduleId: undefined,
        productId: undefined,
    });
    const [panoramaName, setPanoramaName] = useQueryParam('panoramaName', StringParam);
    const [debug, setDebug] = useQueryParam('debug', StringParam);
    const [showBoothSuggestions, setShowBoothSuggestions] = useState(false);
    const [showLiveStream, setShowLiveStream] = useState<string | undefined>();
    const [boothSuggestions, setBoothSuggestions] = useState<Booths.Booth[]>([]);
    const { videoPlayerVisible, stopVideo, playVideo, popupVideo, popVideoPlayerLoading } = usePopupVideoPlayer();
    const { socialLinks, showSocialLinksPopup, socialLinksPopupVisible, hideSocialLinksPopup } = usePopupSocialLinks();
    const {
        addChannels,
        removeChannels,
        addListener,
        removeListener,
        hereNow,
        objects,
        fetchMessages,
        time,
        publish,
    } = useBoothCommunication();
    const { showProfileEditor, toggleProfileEditor } = useProfile();

    const toggleBoothSuggestion = () => {
        setShowBoothSuggestions(!showBoothSuggestions);
    };

    const openBoothSuggestionNotification = () => {
        const args = {
            key: 'booth-suggestion-notification',
            message: (
                <Typography.Text strong style={{ fontSize: '18px' }}>
                    {strings.boothSuggestionNotificationTitle}
                </Typography.Text>
            ),
            description: strings.boothSuggestionNotificationDescription,
            duration: 0,
            btn: (
                <Space>
                    <Button
                        size="small"
                        onClick={() => notification.close('booth-suggestion-notification')}
                        testing-id="deny-booth-suggestions"
                    >
                        {strings.no}
                    </Button>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            toggleBoothSuggestion();
                            notification.close('booth-suggestion-notification');
                        }}
                    >
                        {strings.yes}
                    </Button>
                </Space>
            ),
        };
        notification.info(args);
    };

    const checkForSuggestions = async () => {
        try {
            const boothSuggestions = await apiRequester.getBoothSuggestions();
            if (boothSuggestions && boothSuggestions.length) {
                setBoothSuggestions(boothSuggestions);
                openBoothSuggestionNotification();
            }
        } catch (err) {
            handleError(err);
        }
    };

    const logout = async () => {
        try {
            apiRequester
                .logout()
                .then(() => SendUserActions.logout({ pubnub: context.pubnub!, channel: actionsChannel }))
                .catch(handleError);
            store.remove('user');
            store.remove('token');
            store.remove('refreshToken');
            if (GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM)
                navigate(`/register${typeof window !== 'undefined' ? window.location.search : ''}`);
            else navigate(`/login${typeof window !== 'undefined' ? window.location.search : ''}`);
        } catch (err) {
            handleError(err);
        }
    };

    const endVideoCall = async (request: Modules.VideoCallRequest) => {
        try {
            setLoading(true);
            await apiRequester.endVideoCall({
                boothId: request.booth?._id || GATSBY_GLOBAL_VIDEO_BOOTH_ID,
                moduleId: request?.module?._id!,
                requestId: request?._id!,
            });
            await context.refreshVideoCallRequests();
            await context.refreshScheduledRooms();
            await SendUserActions.endVideoRoom({
                pubnub: context.pubnub!,
                channel: actionsChannel,
                boothId: context.booth?._id || GATSBY_GLOBAL_VIDEO_BOOTH_ID,
                moduleId: request.module?._id!,
                requestId: request._id!,
            });
            await publish({
                channel: `booth-operators.${context.booth?._id || GATSBY_GLOBAL_VIDEO_BOOTH_ID}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: context.booth?._id || GATSBY_GLOBAL_VIDEO_BOOTH_ID,
                    },
                    requester: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName!,
                        lastName: context.user?.lastName!,
                    },
                    action: VIDEO_CALL_ACTIONS.VISITOR_ENDED,
                }),
            });
            handleSuccess(strings.requestCancelled!);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const requestVideoCall = async ({ moduleId }: { moduleId: string }) => {
        try {
            const boothId = context.booth?._id!;
            await apiRequester.requestVideoCall({
                boothId,
                moduleId: moduleId ? moduleId : context?.boothVideoCallModules![0]?._id!,
            });
            await context.refreshVideoCallRequests();
            await context.refreshScheduledRooms();
            await SendUserActions.requestVideoCall({
                pubnub: context.pubnub!,
                channel: actionsChannel,
                boothId: context.booth?._id!,
                moduleId: moduleId ? moduleId : context?.boothVideoCallModules![0]?._id!,
            });
            await publish({
                channel: `booth-operators.${boothId}`,
                message: generateNotifierMessage.videoRequest({
                    action: VIDEO_CALL_ACTIONS.NEW_REQUEST_FROM_VISITOR_TO_OPERATOR,
                    requester: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName!,
                        lastName: context.user?.lastName!,
                    },
                    booth: {
                        id: boothId,
                    },
                }),
            });
        } catch (err) {
            handleError(err);
        }
    };

    const toggleFullScreen = () => {
        if (typeof window !== 'undefined') {
            const document: any = window.document;
            if (
                (document.fullScreenElement && document.fullScreenElement !== null) ||
                (!document.mozFullScreen && !document.webkitIsFullScreen)
            ) {
                if (document.documentElement.requestFullScreen) {
                    document.documentElement.requestFullScreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullScreen) {
                    document.documentElement.webkitRequestFullScreen((Element as any).ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        }
    };

    const showChat = async () => {
        setShowChatModal(true);
        const chatModule =
            context.boothChatModules && context.boothChatModules.length ? context.boothChatModules[0] : undefined;
        const chatModuleId = chatModule?._id;
        await SendUserActions.initiateChat({
            pubnub: context.pubnub!,
            channel: actionsChannel,
            boothId: context.booth?._id!,
            moduleId: chatModuleId!,
        });
    };

    const toggleLiveStreamQuestions = () => {
        if (showLiveStreamingQuestionsModal) setShowLiveStreamQuestionsModal(false);
        else setShowLiveStreamQuestionsModal(true);
    };

    const toggleMannedBooths = () => {
        setShowMannedBoothsModal(!showMannedBoothsModal);
    };

    const toggleDownloadBasket = () => {
        setShowBasket(!showBasket);
    };

    const toggleScheduledCalls = () => {
        setShowScheduledRoomsPanel(!showScheduledRoomsPanel);
    };

    const toggleSiemensContactForm = ({ moduleId }: { moduleId?: string }) => {
        setShowSiemensContactFormId(moduleId);
    };

    const toggleProductView = ({ moduleId, productId }: { moduleId?: string; productId?: string }) => {
        setActiveProduct({ moduleId, productId });
    };

    const iframeEventListener = async (event: MessageEvent) => {
        try {
            const {
                action,
                boothId,
                moduleId,
                type,
                skipFileDownloadList = false,
                productId,
            } = event.data as EventCommunication.Message;
            if (action) console.log(action);
            if (action === 'TOGGLE_FULL_SCREEN') toggleFullScreen();
            else if (action === 'LOGOUT') logout();
            else if (action === 'SELECT_BOOTH') {
                const currentBoothId = context.booth?._id;
                console.log({ currentBoothId, boothId });
                if (boothId) {
                    if (type && type === 'lite') {
                        setLiteBoothModal(true);
                    }

                    const [operators] = await Promise.all([
                        hereNow({ channels: [`booth-operators.${boothId}`] }) as any,
                        currentBoothId && currentBoothId !== boothId
                            ? SendUserActions.exitBooth({
                                  pubnub: context.pubnub!,
                                  channel: actionsChannel,
                                  boothId: currentBoothId,
                              })
                            : Promise.resolve(),

                        !currentBoothId || currentBoothId !== boothId
                            ? SendUserActions.enterBooth({
                                  pubnub: context.pubnub!,
                                  channel: actionsChannel,
                                  boothId: boothId,
                              })
                            : Promise.resolve(),
                        context.selectBooth({ boothId }),

                        // Tell dashboard to refresh chat list
                        publish({
                            channel: `booth-operators.${context.booth?._id!}`,
                            message: generateNotifierMessage.chat({
                                booth: {
                                    id: context.booth?._id!,
                                },
                                sender: {
                                    id: context.user?._id!,
                                    firstName: context.user?.firstName!,
                                    lastName: context.user?.lastName!,
                                },
                                action: CHAT_ACTIONS.CHAT_UPDATED,
                            }),
                        }),
                    ]);

                    setChannels([
                        `users.${user._id}`,
                        `booth-visitors.${boothId}`,
                        `booth-operators.${boothId}-pnpres`,
                    ]);

                    const totalOccupancy = (operators as any).totalOccupancy;
                    setOperatorsOnline(totalOccupancy ? true : false);
                } else {
                    if (currentBoothId)
                        await SendUserActions.exitBooth({
                            pubnub: context.pubnub!,
                            channel: actionsChannel,
                            boothId: currentBoothId,
                        });
                    await context.selectBooth({});
                    setChannels([`users.${user._id}`]);
                }
            } else if (action === 'REQUEST_VIDEO_CALL') {
                if (type === 'scheduled') {
                    setShowVideoRoomScheduleModal(moduleId ? moduleId : true);
                } else requestVideoCall({ moduleId: moduleId! });
            } else if (action === 'TOGGLE_FILE_DOWNLOAD') {
                console.log({ moduleId, skipFileDownloadList });
                setSkipFileDownloadList(skipFileDownloadList);
                setActiveFileDownloadModuleId(moduleId);
            } else if (action === 'TOGGLE_CHAT') showChat();
            else if (action === 'TOGGLE_LIVE_STREAM_QUESTIONS') toggleLiveStreamQuestions();
            else if (action === 'TOGGLE_MANNED_BOOTHS') toggleMannedBooths();
            else if (action === 'TOGGLE_DOWNLOAD_BASKET') toggleDownloadBasket();
            else if (action === 'TOGGLE_SCHEDULED_CALLS') toggleScheduledCalls();
            else if (action === 'TOGGLE_SIEMENS_CONTACT_FORM') toggleSiemensContactForm({ moduleId });
            else if (action === 'SHOW_PRODUCT') toggleProductView({ moduleId, productId });
            else if (action === 'SHOW_LIVE_STREAM') setShowLiveStream(moduleId);
            else if (action === 'PLAY_VIDEO')
                playVideo({
                    moduleId: event.data.moduleId,
                    videoId: event.data.videoId,
                    videoIndex: event.data.videoIndex,
                });
            else if (action === 'SHOW_SOCIAL_LINKS') showSocialLinksPopup();
            else if (action === 'TOGGLE_PROFILE_EDITOR') toggleProfileEditor();
        } catch (err) {
            handleError(err);
        }
    };

    const closeLiteBooth = async () => {
        const currentBoothId = context.booth?._id;
        setLiteBoothModal(false);
        if (currentBoothId)
            await SendUserActions.exitBooth({
                pubnub: context.pubnub!,
                channel: actionsChannel,
                boothId: currentBoothId,
            });
        await context.selectBooth({});
        setChannels([`users.${user._id}`]);
    };

    const notifierMessageHandler = async (messageEvent: PubNub.MessageEvent) => {
        try {
            console.log(messageEvent);
            const { message } = messageEvent;
            const { id, action, booth } = message;
            if (id) console.log(id);

            if (action && action === VIDEO_CALL_ACTIONS.OPERATOR_ACCEPTED.toString())
                showNotification('success', strings.callAccepted, strings.callAcceptMsg);
            else if (action && action === VIDEO_CALL_ACTIONS.OPERATOR_REJECTED.toString())
                showNotification('error', strings.callRejected, strings.callRejectMsg);
            switch (id) {
                case NOTIFIER_MESSAGE_IDS.VIDEO_CALL:
                    if (booth.id === context.booth?._id || booth.id === GATSBY_GLOBAL_VIDEO_BOOTH_ID) {
                        await context.refreshVideoCallRequests();
                        await context.refreshScheduledRooms();
                    }
                    break;
                case NOTIFIER_MESSAGE_IDS.CHAT:
                    if (booth.id === context.booth?._id) {
                        sound.play();
                        handleSuccess(strings.newMessage!);
                        setShowChatModal(true);
                    }
                    break;
                case NOTIFIER_MESSAGE_IDS.LIVE_STREAMING:
                    if (booth.id === context.booth?._id && action === LIVE_STREAM_ACTIONS.QUESTION_APPROVED) {
                        setRefreshLiveStreamQuestions(Math.random().toString());
                    }
                    break;
                default:
                    break;
            }
        } catch (err) {
            handleError(err);
        }
    };

    const presenceHandler = async (presenceEvent: PubNub.PresenceEvent) => {
        const operatorChannel = `booth-operators.${context.booth?._id}`;
        if (presenceEvent.channel === operatorChannel) {
            const hereNowResp = await hereNow({ channels: [operatorChannel] });
            const operators = hereNowResp.totalOccupancy;
            setOperatorsOnline(operators ? true : false);
        }
    };

    const notifierMessageListener = {
        message: notifierMessageHandler,
        presence: presenceHandler,
    };

    const checkForNewVideoCallRequests = () => {
        const receivedActiveVideoCallRequest = context.boothVideoCallRequests?.find(
            request => request?.status === 'requested' && request.requester === 'operator',
        );
        if (receivedActiveVideoCallRequest) setActiveVideoCallRequest(receivedActiveVideoCallRequest);
        else setActiveVideoCallRequest(undefined);
    };

    const checkForOngoingVideoCall = () => {
        const updatedOngoingVideoCall = context.boothVideoCallRequests?.find(request => {
            const isScheduled = request?.room?.isScheduled;
            const currentTime = new Date().getTime();
            if (!isScheduled) return request?.status === 'accepted' && request.room;
            else {
                const startTime = new Date(request?.room?.startTime!).getTime();
                const endTime = new Date(request?.room?.endTime!).getTime();
                return (
                    request?.status === 'accepted' &&
                    request.room &&
                    request?.room?.link &&
                    currentTime >= startTime &&
                    currentTime < endTime
                );
            }
        });
        if (JSON.stringify(updatedOngoingVideoCall) !== JSON.stringify(ongoingVideoCall)) {
            if (updatedOngoingVideoCall) setOngoingVideoCall(updatedOngoingVideoCall);
            else setOngoingVideoCall(undefined);
        }
    };

    const showVideoCallMessage = () => {
        const videoCallRequests = context.boothVideoCallRequests;
        const acceptedRequest = videoCallRequests?.find(request => request?.status === 'accepted' && !request.room);
        const requestedVideoCallRequest = videoCallRequests?.find(
            request =>
                request?.status === 'requested' &&
                request?.requester === 'visitor' &&
                request?.visitor?._id === context.user?._id,
        );

        if (acceptedRequest)
            message.loading({
                content: (
                    <>
                        <span testing-id="alert-message-text">{strings.waitForOperatorToJoin}</span>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => endVideoCall(acceptedRequest)}
                            loading={loading}
                            testing-id="alert-message-action-button"
                        >
                            {strings.cancel}
                        </Button>
                    </>
                ),
                key: 'video-call-message',
                duration: 0,
                top: 32,
            });
        else if (requestedVideoCallRequest)
            message.loading({
                content: (
                    <>
                        <span testing-id="alert-message-text">{strings.waitForOperatorToAccept}</span>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => endVideoCall(requestedVideoCallRequest)}
                            loading={loading}
                            testing-id="alert-message-action-button"
                        >
                            {strings.cancel}
                        </Button>
                    </>
                ),
                key: 'video-call-message',
                duration: 0,
                top: 32,
            });
        else {
            message.destroy('video-call-message');
        }
    };

    useEffect(() => {
        toggleFullScreen();
    }, []);

    useEffect(() => {
        const user = store.get('user') as Users.User;
        if (!user?._id && context.eventAuthModule !== undefined) logout();
        else context.setUser(user);
        if (['huethig'].includes(GATSBY_CLIENT_NAME)) checkForSuggestions();
    }, []);

    useEffect(() => {
        addChannels(channels);
        return () => {};
    }, [channels]);

    useEffect(() => {
        addListener(notifierMessageListener);
        return () => {
            removeListener(notifierMessageListener);
        };
    }, [context.booth?._id, context.boothVideoCallModules, context.boothVideoCallRequests]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('message', iframeEventListener);
            return () => {
                window.removeEventListener('message', iframeEventListener);
            };
        }
    }, [
        context.boothModules,
        context.boothVideoCallModules,
        context.boothChatModules,
        context.boothFileDownloadModules,
        context.booth,
        context.boothVideoCallRequests,
        context.scheduledRooms,
    ]);

    useEffect(() => {
        const user = store.get('user') as Users.User;
        if (user?._id) {
            showVideoCallMessage();
            checkForOngoingVideoCall();
            checkForNewVideoCallRequests();
        }
    }, [context.boothVideoCallRequests, context.boothVideoCallModules]);

    useEffect(() => {
        const interval = setInterval(() => {
            const user = store.get('user') as Users.User;
            if (user?._id) {
                checkForOngoingVideoCall();
            }
        }, 30000);
        return () => {
            clearInterval(interval);
        };
    }, [context.boothVideoCallRequests, context.boothVideoCallModules]);

    useEffect(() => {
        const checkAndActivateScheduledCalls = setInterval(() => {
            const currentCall = context.boothVideoCallRequests?.find(request => {
                const isScheduled = request?.room?.isScheduled;
                if (!isScheduled) return request?.status === 'accepted' && request.room;
            });
            if (currentCall) {
                setOngoingVideoCall(ongoingVideoCall => {
                    if (!ongoingVideoCall) return currentCall;
                    else return ongoingVideoCall;
                });
            }
        }, 10000);

        return () => {
            clearInterval(checkAndActivateScheduledCalls);
        };
    }, [context.boothVideoCallRequests, context.scheduledRooms, lastAlertedScheduledCallIds]);

    useEffect(() => {
        const interval = setInterval(() => {
            context.refreshScheduledRooms().then(rooms => {
                rooms.forEach(room => {
                    const roomId = room?._id;
                    const roomName = room?.name;
                    const currentTime = new Date().getTime();
                    const startTime = new Date(room?.startTime!).getTime();
                    const secondsRemainingToStart = startTime - currentTime;

                    if (
                        secondsRemainingToStart / 1000 <= 300 &&
                        secondsRemainingToStart / 1000 > 0 &&
                        !lastAlertedScheduledCallIds.includes(roomId!)
                    ) {
                        handleSuccess(
                            `${roomName} will be starting in less than 5 mins. Please be present at the booth to attend it.`,
                        );
                        setLastAlertedScheduledCallIds(roomIds => {
                            roomIds.push(roomId!);
                            return roomIds;
                        });
                    }
                });
            });
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        pubnubClientForActions.subscribe({ channels: [actionsChannel], withPresence: true });
        return () => pubnubClientForActions.unsubscribe({ channels: [actionsChannel] });
    }, []);

    return (
        <FullScreen handle={handle} className="fullscreen">
            {debug !== 'true' && (
                <iframe
                    id={IFRAME_NAME}
                    src={panoramaName ? EVENT_URL + `?panoramaName=${panoramaName}` : EVENT_URL}
                    style={{
                        height:
                            !['siemens'].includes(GATSBY_CLIENT_NAME) && context.booth ? 'calc(100vh - 25px)' : '100vh',
                        width: '100vw',
                        overflow: 'hidden',
                    }}
                    frameBorder={0}
                    scrolling="no"
                />
            )}

            <VideoCallRequestModal
                activeVideoCallRequest={activeVideoCallRequest}
                setActiveVideoCallRequest={setActiveVideoCallRequest}
            />

            <VideoCallModal request={ongoingVideoCall} />

            <FileDownloadsModal
                moduleId={activeFileDownloadsModuleId}
                setModuleId={setActiveFileDownloadModuleId}
                skipFileDownloadList={skipFileDownloadList}
                setSkipFileDownloadList={setSkipFileDownloadList}
            />

            <PubNubProvider client={pubnubClientForChat}>
                <ChatModal
                    showChatModal={showChatModal}
                    setShowChatModal={setShowChatModal}
                    operatorsOnline={operatorsOnline}
                />
            </PubNubProvider>

            {!['siemens'].includes(GATSBY_CLIENT_NAME) && context.booth && <OnlineStatus online={operatorsOnline} />}

            <DownloadBasket showBasket={showBasket} setShowBasket={setShowBasket} />

            <ScheduledRooms
                showScheduledRoomsPanel={showScheduledRoomsPanel}
                setShowScheduledRoomsPanel={setShowScheduledRoomsPanel}
            />

            <VideoRoomScheduleModal
                showVideoRoomScheduleModal={showVideoRoomScheduleModal ? true : false}
                setShowVideoRoomScheduleModal={setShowVideoRoomScheduleModal}
                boothId={context.booth?._id!}
                moduleId={
                    showVideoRoomScheduleModal && typeof showVideoRoomScheduleModal === 'string'
                        ? showVideoRoomScheduleModal
                        : context?.boothVideoCallModules![0]?._id!
                }
            />

            <LiveStreamingQuestions
                showLiveStreamQuestionsModal={showLiveStreamingQuestionsModal}
                setShowLiveStreamQuestionsModal={setShowLiveStreamQuestionsModal}
                refresh={refreshLiveStreamQuestion}
            />

            <MannedBooths
                showMannedBoothsModal={showMannedBoothsModal}
                setShowMannedBoothsModal={setShowMannedBoothsModal}
            />

            <LiteBoothModal visible={liteBoothModal} onCancel={closeLiteBooth} />

            <SiemensContactForm visible={showSiemensContactFormId} onCancel={() => toggleSiemensContactForm({})} />

            {['siemens'].includes(GATSBY_CLIENT_NAME) && <SiemensChatModal />}
            {['siemens'].includes(GATSBY_CLIENT_NAME) && <SiemensVideoCallModal />}

            <ProductModal
                moduleId={activeProduct?.moduleId}
                productId={activeProduct?.productId}
                toggleProductView={toggleProductView}
            />

            <TheatreModal
                showLiveStream={showLiveStream}
                setShowLiveStream={setShowLiveStream}
                toggleLiveStreamQuestions={toggleLiveStreamQuestions}
            />

            <BoothSuggestions show={showBoothSuggestions} setShow={setShowBoothSuggestions} booths={boothSuggestions} />

            <PopupVideoPlayer
                visible={videoPlayerVisible}
                stopVideo={stopVideo}
                video={popupVideo}
                loading={popVideoPlayerLoading}
            />

            <PopupSocialLinks
                visible={socialLinksPopupVisible}
                socialLinks={socialLinks}
                hidePopup={hideSocialLinksPopup}
            />

            <ProfileEditor visible={showProfileEditor} toggleProfileEditor={toggleProfileEditor} />

            {debug === 'true' && <Debug />}
        </FullScreen>
    );
};

export default EventFrame;
