import React, { useContext } from 'react';
import { GlobalContext } from '../context/GlobalContextProvider';

const postMessage = (message: {
    action:
        | 'SELECT_BOOTH'
        | 'TOGGLE_CHAT'
        | 'TOGGLE_FILE_DOWNLOAD'
        | 'TOGGLE_LIVE_STREAM_QUESTIONS'
        | 'TOGGLE_PROFILE_EDITOR'
        | 'REQUEST_VIDEO_CALL'
        | 'SHOW_PRODUCT'
        | 'SHOW_LIVE_STREAM';
    boothId?: string;
    moduleId?: string;
}) => {
    window.postMessage(message, '*');
};

export const Debug = () => {
    const context = useContext(GlobalContext);
    return (
        <div
            style={{ zIndex: 1000, position: 'fixed', top: 0, left: 0, overflowY: 'scroll', maxHeight: '100vh' }}
            id="debugger"
        >
            <div id="status">
                <h2>Status</h2>
                <div id="active-booth-id" active-booth-id={context.booth?._id || 'none'}>
                    Current Booth:{' '}
                    {context.booth ? (
                        <>
                            {context.booth?.name}{' '}
                            <span id={`active-booth-${context.booth._id}`}>{context.booth?._id}</span>
                        </>
                    ) : (
                        'none'
                    )}
                </div>
            </div>
            <div id="actions">
                <h2>Actions</h2>
                <div id="navigate-to-booth">
                    <h3>Global Action - Navigate to booths</h3>
                    {context.booths?.map(booth => (
                        <>
                            <button
                                key={booth._id}
                                id={`select-booth-${booth._id}`}
                                onClick={() => postMessage({ action: 'SELECT_BOOTH', boothId: booth._id })}
                            >
                                Navigate to {booth.name} ({booth._id})
                            </button>{' '}
                            <br />
                        </>
                    ))}
                </div>
                <div id="toggle-profile-editor">
                    <h3>Global Action - Toggle Profile Editor</h3>
                    <button
                        key="toggle-profile-editor"
                        id={`toggle-profile-editor`}
                        onClick={() => postMessage({ action: 'TOGGLE_PROFILE_EDITOR' })}
                    >
                        Toggle Profile Editor
                    </button>
                    <br />
                </div>
                <div id="live-streaming">
                    <h3>Booth Action - Toggle Live Stream Question</h3>
                    {context.boothModules
                        ?.filter(module => module?.type === 'live-streaming')
                        ?.map(module => (
                            <>
                                <button
                                    key={module?._id}
                                    id={`toggle-live-stream-question-${module?._id}`}
                                    onClick={() => postMessage({ action: 'TOGGLE_LIVE_STREAM_QUESTIONS' })}
                                >
                                    Toggle live stream question {module?._id}
                                </button>{' '}
                                <br />
                            </>
                        ))}
                </div>
                <div id="chat">
                    <h3>Booth Action - Booth Chat Toggle</h3>
                    {context.boothChatModules?.map(module => (
                        <>
                            <button
                                key={module?._id}
                                id={`toggle-chat-${module?._id}`}
                                onClick={() => postMessage({ action: 'TOGGLE_CHAT' })}
                            >
                                Toggle chat {module?._id}
                            </button>{' '}
                            <br />
                        </>
                    ))}
                </div>
                <div id="file-downloads">
                    <h3>Booth Action - Booth File Downloads Toggle</h3>
                    {context.boothModules
                        ?.filter(module => module?.type === 'file-downloads')
                        ?.map(module => (
                            <>
                                <button
                                    key={module?._id}
                                    id={`toggle-file-download-${module?._id}`}
                                    onClick={() =>
                                        postMessage({ action: 'TOGGLE_FILE_DOWNLOAD', moduleId: module?._id })
                                    }
                                >
                                    Toggle file download {module?._id}
                                </button>{' '}
                                <br />
                            </>
                        ))}
                </div>
                <div id="video-call">
                    <h3>Booth Action - Request Video Call</h3>
                    {context.boothModules
                        ?.filter(module => module?.type === 'video-call')
                        ?.map(module => (
                            <>
                                <button
                                    key={module?._id}
                                    id={`request-video-call-${module?._id}`}
                                    onClick={() => postMessage({ action: 'REQUEST_VIDEO_CALL', moduleId: module?._id })}
                                >
                                    Request Video Call {module?._id} {module?.name}
                                </button>{' '}
                                <br />
                            </>
                        ))}
                </div>
                <div id="video-call">
                    <h3>Booth Action - Product View</h3>
                    {context.boothModules
                        ?.filter(module => module?.type === 'products')
                        ?.map(module => (
                            <>
                                <button
                                    key={module?._id}
                                    id={`view-products-${module?._id}`}
                                    onClick={() =>
                                        postMessage({
                                            action: 'SHOW_PRODUCT',
                                            moduleId: module?._id,
                                        })
                                    }
                                >
                                    View Products {module?._id} {module?.name}
                                </button>{' '}
                                <br />
                            </>
                        ))}
                </div>
                <div id="live-stream">
                    <h3>Booth Action - Live Stream</h3>
                    {context.boothModules
                        ?.filter(module => module?.type === 'live-streaming')
                        ?.map(module => (
                            <>
                                <button
                                    key={module?._id}
                                    id={`watch-live-stream-${module?._id}`}
                                    onClick={() =>
                                        postMessage({
                                            action: 'SHOW_LIVE_STREAM',
                                            moduleId: module?._id,
                                        })
                                    }
                                >
                                    Watch Live Stream {module?._id} {module?.name}
                                </button>{' '}
                                <br />
                            </>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Debug;
