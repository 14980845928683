import React, { SetStateAction, useContext, useEffect, useState } from 'react';

import { EyeOutlined, QuestionOutlined } from '@ant-design/icons';
import { CloseCircleFilled, CloseCircleTwoTone } from '@ant-design/icons';
import Vimeo from '@u-wave/react-vimeo';
import { create, MediaPlayer, PlayerEventType, PlayerState } from 'amazon-ivs-player';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';
import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import { Modal, List, Button, Space, Drawer, Tag, Card } from 'antd';
import LocalizedStrings from 'react-localization';
import Youtube from 'react-youtube';
import ytdl from 'ytdl-core';

import './LiteBoothModal.css';

// @ts-ignore
// @ts-ignore

import { GlobalContext } from '../context/GlobalContextProvider';
import { useBoothCommunication } from '../hooks';
import { apiRequester, handleError, handleSuccess, SendUserActions } from '../utility';

const EVENT_ID = process.env.GATSBY_EVENT_ID!;
const CLIENT_ID = process.env.GATSBY_CLIENT_ID!;

const strings = new LocalizedStrings({
    en: {
        fileDownloads: 'File Downloads',
        download: 'Download',
        preview: 'Preview',
        addToBasket: 'Add to Basket',
        questions: 'Questions',
        viewRecordings: 'Recordings',
    },
    de: {
        fileDownloads: 'Downloads',
        download: 'Download',
        preview: 'Vorschau',
        addToBasket: 'Zum Warenkorb hinzufügen',
        questions: 'Fragen',
        viewRecordings: 'Aufnahmen',
    },
});

export const TheatreModal = ({
    showLiveStream,
    setShowLiveStream,
    toggleLiveStreamQuestions,
}: {
    showLiveStream: string | undefined;
    setShowLiveStream: React.Dispatch<SetStateAction<string | undefined>>;
    toggleLiveStreamQuestions: () => void;
}) => {
    const context = useContext(GlobalContext);
    const [playerState, setPlayerState] = useState<'idle' | 'playing'>('idle');
    const [player, setPlayer] = useState<MediaPlayer>();
    const [videos, setLiveStreamVideos] = useState<Modules.VideoModuleVideo[]>([]);
    const [showVideos, setShowVideos] = useState(false);
    const {
        objects,
        fetchMessages,
        hereNow,
        time,
        addChannels,
        removeChannels,
        addListener,
        removeListener,
        publish,
    } = useBoothCommunication();

    const subscribeToLiveStreamChannel = async () => {
        console.log('calling subscribeToLiveStreamChannel');
        const moduleId = showLiveStream;
        addChannels([`live-stream.${moduleId}`]);
    };

    const unSubscribeToLiveStreamChannel = () => {
        console.log('calling unSubscribeToLiveStreamChannel');
        const moduleId = showLiveStream;
        removeChannels([`live-stream.${moduleId}`]);
    };

    const refreshLiveStreamVideos = async ({ boothId, moduleId }: { boothId: string; moduleId: string }) => {
        try {
            const videos = await apiRequester.getModuleVideos({ moduleId, boothId });
            setLiveStreamVideos(videos);
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        const boothId = context.booth?._id;
        const moduleId = showLiveStream;
        if (moduleId && boothId) {
            refreshLiveStreamVideos({ moduleId, boothId });
            // Get module data
            apiRequester
                .getLiveStreamLink<Modules.LiveStreamingData>({ boothId, moduleId })
                .then(link => {
                    // Get live stream url
                    const playbackUrl = link.playbackUrl;
                    if (playbackUrl) {
                        // Render video player
                        const videoElement: HTMLVideoElement | null = document.querySelector('#video-player');
                        const createAbsolutePath = (assetPath: string) => new URL(assetPath, document.URL).toString();
                        const player: MediaPlayer = create({
                            wasmWorker: createAbsolutePath(wasmWorkerPath),
                            wasmBinary: createAbsolutePath(wasmBinaryPath),
                        });
                        player.attachHTMLVideoElement(videoElement!);
                        player.setAutoplay(true);
                        player.load(playbackUrl);
                        player.play();
                        player.addEventListener(PlayerState.IDLE, () => {
                            setPlayerState('idle');
                        });
                        player.addEventListener(PlayerState.ENDED, () => {
                            setTimeout(() => {
                                player.load(playbackUrl);
                                player.setRebufferToLive(true);
                            }, 3000);
                        });
                        player.addEventListener(PlayerEventType.ERROR, err => {
                            console.error(err);
                            setTimeout(() => {
                                player.load(playbackUrl);
                                player.setRebufferToLive(true);
                            }, 3000);
                        });
                        player.addEventListener(PlayerState.PLAYING, err => {
                            setPlayerState('playing');
                        });
                        setPlayer(player);
                    }
                });
            subscribeToLiveStreamChannel();

            return () => {
                unSubscribeToLiveStreamChannel();
            };
        }
    }, [showLiveStream]);

    return (
        <Modal
            destroyOnClose={true}
            open={!!showLiveStream}
            onCancel={() => {
                setShowLiveStream(undefined);
            }}
            footer={null}
            style={{ minWidth: '80vw' }}
            bodyStyle={{ padding: '10px' }}
            centered
            closeIcon={<CloseCircleTwoTone style={{ fontSize: '1.5rem' }} />}
        >
            <video
                style={{ width: '100%', borderRadius: '10px' }}
                id="video-player"
                testing-player-state={playerState}
                playsInline
                autoPlay
                controls
            ></video>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Space>
                    <Button icon={<QuestionOutlined />} onClick={toggleLiveStreamQuestions} size="small" type="primary">
                        {strings.questions}
                    </Button>
                    {/* <Button icon={<EyeOutlined />} onClick={() => setShowVideos(true)} size="small" type="primary">
                        {strings.viewRecordings}
                    </Button> */}
                </Space>
            </div>
            <Drawer onClose={() => setShowVideos(false)} open={showVideos} title={strings.viewRecordings} width="620px">
                <List
                    dataSource={videos}
                    renderItem={video => (
                        <List.Item>
                            {video.source === 'vimeo' ? (
                                <Vimeo
                                    video={video?.info?.id}
                                    style={{ width: '100%', padding: 0 }}
                                    responsive={true}
                                    autoplay={false}
                                />
                            ) : (
                                <Youtube
                                    containerClassName="yt-video-container-max-width"
                                    className="yt-video-max-width"
                                    videoId={ytdl.getVideoID(video?.link!)}
                                    opts={{
                                        width: '100%',
                                        playerVars: {
                                            // https://developers.google.com/youtube/player_parameters
                                            autoplay: 0,
                                        },
                                    }}
                                />
                            )}
                        </List.Item>
                    )}
                />
            </Drawer>
        </Modal>
    );
};

export default TheatreModal;
